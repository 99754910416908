module.exports = {
  transparent: 'transparent',
  current: 'currentColor',
  primary: {
    50: '#F8FEFC',
    100: '#E6FCF5',
    200: '#C0F7E5',
    300: '#02DE98',
    400: '#08BB92',
    500: '#0D988B',
    600: '#096B62',
    650: '#2A3B48',
    700: '#2D2D42',
    800: '#181822',
  },
  gray: {
    50: '#F5F5F5',
    100: '#E7E7E7',
    150: '#D8D8D8',
    200: '#787878',
  },
  white: '#FFFFFF',
  red: {
    100: '#FFE5E5',
    200: '#FF3C3C',
  },
  orange: {
    100: '#FF8105',
  },
  purple: {
    100: '#9F9FD3',
    200: '#53538F',
  },
  brown: {
    100: '#D6C4BD',
    200: '#BD9276',
    300: '#9E7157',
    400: '#8E634D',
  },
  yellow: {
    100: '#F9F8E4',
    200: '#FCFCB9',
    300: '#FAFF1A',
  },
  blue: {
    100: '#D3E9FE',
    200: '#A8D2FC',
    300: '#7CBCFB',
    400: '#50A5F9',
    500: '#3496f9',
  },
  black: '#000000',
  crops: {
    sky: '#D3E9FE',
    earth: '#9D7157',
  },
};
