import {Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api';
import React, {CSSProperties, useCallback, useMemo, useState} from 'react';

import colors from '../../../../styles/colors';
import {formatCoordinate} from '../decision/util';

export const BRISTOL = {
  lat: 51.5209,
  lng: -2.66592,
  postcode: 'BS11 0RS',
};
const UK_BOUNDS = {
  south: 49.86,
  north: 60.87,
  west: -8.66,
  east: 1.77,
};
const MAP_MARKER_ICON = '/img/mapMarker.svg';
const MAP_MARKER_DARK_ICON = '/img/mapMarkerDark.svg';
const SPOT_MARKER_SELECTED = '/img/spotMarkerSelected.svg';
const SPOT_MARKER_UNSELECTED = '/img/spotMarkerUnselected.svg';

export const isLatitudeInRange = (lat: number) => lat >= UK_BOUNDS.south && lat <= UK_BOUNDS.north;
export const isLongitudeInRange = (lng: number) => lng >= UK_BOUNDS.west && lng <= UK_BOUNDS.east;
const isCoordinateInRange = (lat: number, lng: number) => isLatitudeInRange(lat) && isLongitudeInRange(lng);

type Spot = {
  lat: number;
  lng: number;
  selected: boolean;
};

type MapProps = {
  googleMapsApiKey: string;
  zoom?: number;
  markerPosition?: {lat: number; lng: number};
  onMarkerPositionUpdate?: (lat: number, lng: number) => void;
  fixedMarker?: boolean;
  spots?: Spot[];
  onSpotClick?: (spotIdx: number) => void;
  currentLocation?: {lat: number; lng: number};
  radius?: number;
  onError?: (message: string) => void;
  style?: CSSProperties;
};

const Map = (props: MapProps) => {
  const {
    googleMapsApiKey,
    zoom = 7,
    markerPosition,
    onMarkerPositionUpdate,
    fixedMarker,
    spots,
    currentLocation,
    radius,
    onSpotClick,
    onError,
    style,
  } = props;
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [isHoveringCurrentLocation, setIsHoveringCurrentLocation] = useState(false);
  const markerLatLng = useMemo(() => {
    if (!markerPosition || !isLoaded || !map) return undefined;
    if (!isCoordinateInRange(markerPosition?.lat, markerPosition.lng)) {
      if (onError) onError('Marker out of bounds');
      return undefined;
    }
    const latLng = new google.maps.LatLng({lat: markerPosition?.lat, lng: markerPosition.lng});
    map.panTo(latLng);
    return latLng;
  }, [isLoaded, map, markerPosition, onError]);

  const handleMapLoad = (map: google.maps.Map) => setMap(map);
  const handleMapUnmount = () => setMap(undefined);

  const handleMarkerUpdate = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (!e.latLng || !onMarkerPositionUpdate) return;
      const latitude = Number(formatCoordinate(e.latLng.lat()));
      const longitude = Number(formatCoordinate(e.latLng.lng()));

      onMarkerPositionUpdate(latitude, longitude);
    },
    [onMarkerPositionUpdate]
  );

  const handleCurrentLocationHover = useCallback(() => {
    console.log('hovering');
    setIsHoveringCurrentLocation(true);
  }, []);

  const handleCurrentLocationOut = useCallback(() => setIsHoveringCurrentLocation(false), []);

  const containerStyle = useMemo(
    () => ({
      height: '235px',
      overflow: 'hidden',
      borderRadius: '30px',
      ...style,
    }),
    [style]
  );

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName={'[&_*.gm-ui-hover-effect]:!hidden'}
          mapContainerStyle={containerStyle}
          center={BRISTOL}
          zoom={zoom}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              position: google.maps.ControlPosition.RIGHT_TOP,
              mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.TERRAIN,
                google.maps.MapTypeId.SATELLITE,
                google.maps.MapTypeId.HYBRID,
              ],
            },
            keyboardShortcuts: false,
            clickableIcons: false,
            restriction: {
              latLngBounds: UK_BOUNDS,
              strictBounds: false,
            },
          }}
          onClick={fixedMarker ? undefined : handleMarkerUpdate}
          onLoad={handleMapLoad}
          onUnmount={handleMapUnmount}
        >
          {markerLatLng && (
            <Marker
              position={markerLatLng}
              icon={{
                url: MAP_MARKER_ICON,
                anchor: new google.maps.Point(15, 45),
              }}
              draggable={!fixedMarker}
              onDragEnd={handleMarkerUpdate}
              clickable={!fixedMarker}
            />
          )}
          {spots?.map((spot, idx) => (
            <Marker
              key={idx}
              position={{lat: spot.lat, lng: spot.lng}}
              icon={
                spot.selected
                  ? {url: SPOT_MARKER_SELECTED, anchor: new google.maps.Point(14, 14)}
                  : {url: SPOT_MARKER_UNSELECTED, anchor: new google.maps.Point(7, 7)}
              }
              onClick={() => onSpotClick?.(idx)}
            />
          ))}
          {currentLocation && (
            <Marker
              position={currentLocation}
              icon={{url: MAP_MARKER_DARK_ICON, anchor: new google.maps.Point(11, 31)}}
              onMouseOver={handleCurrentLocationHover}
              onMouseOut={handleCurrentLocationOut}
            >
              {isHoveringCurrentLocation && (
                <InfoWindow>
                  <span>Chosen location</span>
                </InfoWindow>
              )}
            </Marker>
          )}
          {!!currentLocation && !!radius && (
            <Circle
              center={currentLocation}
              radius={radius}
              draggable={false}
              options={{
                clickable: false,
                fillColor: colors.primary['500'],
                fillOpacity: 0.2,
                strokeColor: colors.primary['500'],
                strokeWeight: 1,
              }}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
