import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement, useId} from 'react';

import {QuestionMarkCircleIcon} from '../../../../resources/Icons';
import SimpleTooltip from '../SimpleTooltip';

type Props = {
  className?: string;
  tooltipClassName?: string;
  tooltipProps?: React.ComponentProps<typeof SimpleTooltip>;
};
const HelpTooltipButton = ({
  children,
  className,
  tooltipClassName,
  tooltipProps,
}: PropsWithChildren<Props>): ReactElement => {
  const tooltipId = useId();

  return (
    <>
      <button
        type={'button'}
        data-tooltip-id={tooltipId}
        className={classNames('w-[2.2rem] h-2.2rem text-gray-200 hover:text-primary-300', className)}
        aria-label={'Toggle help tooltip'}
      >
        <QuestionMarkCircleIcon />
      </button>
      <SimpleTooltip id={tooltipId} clickable className={classNames('max-w-3xl', tooltipClassName)} {...tooltipProps}>
        {children}
      </SimpleTooltip>
    </>
  );
};

export default HelpTooltipButton;
