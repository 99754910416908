import haversineDistance from 'haversine-distance';
import {ParsedUrlQuery} from 'querystring';

import {Media, Point, Product, ProductInput, ProductSearchParams, Supplier, SupplierInput} from '../../query/graphql';
import {CMSMedia} from '../../strapi/types';
import {
  PRODUCT_DEFAULT_SORT,
  ProfileStatus,
  SortOrder,
  StrapiProduct,
  StrapiSupplier,
  SUPPLIER_DEFAULT_SORT,
} from './types';
export const MAX_ALLOWED_PRODUCTS = 50;

const METERS_PER_MILE = 1609.344;

export function numberToString(value?: number): string {
  return value === undefined ? '' : String(value);
}

export function parseOptionalFloat(value: string | undefined, throws: boolean = false): number | undefined {
  const numeric = value ? parseFloat(value) : undefined;
  if (numeric === undefined || !isNaN(numeric)) {
    return numeric;
  } else {
    if (throws) {
      throw new Error(`Invalid numeric value: ${value}`);
    } else {
      return undefined;
    }
  }
}

export function firstValue(param: string | string[] | undefined): string | undefined {
  return Array.isArray(param) ? param[0] : param;
}

function firstNumericValue(param: string | string[] | undefined): number | undefined {
  const value = firstValue(param);
  const numeric = parseOptionalFloat(value);
  return numeric !== undefined && !isNaN(numeric) ? numeric : undefined;
}

function ensureArrayOrUndefined<T>(value: undefined): undefined;
function ensureArrayOrUndefined<T>(value: T | T[]): T[];
function ensureArrayOrUndefined<T>(value: T | T[] | undefined): T[] | undefined {
  return Array.isArray(value) ? value : value ? [value] : undefined;
}

export function queryToSearchParams(query: ParsedUrlQuery, defaultSort: keyof typeof SortOrder): ProductSearchParams {
  const params: ProductSearchParams = {sort: defaultSort};
  if (query.q) {
    params.query = firstValue(query.q);
  }
  if (query.pc) {
    params.postcode = firstValue(query.pc);
  }
  if (query.lat && query.lng) {
    const latitude = firstNumericValue(query.lat);
    const longitude = firstNumericValue(query.lng);
    if (latitude !== undefined && longitude !== undefined) {
      params.location = {latitude, longitude};
    }
  }
  if (query.px) {
    params.proximity = firstNumericValue(query.px);
  }
  if (query.s) {
    params.suppliers = ensureArrayOrUndefined(query.s);
  }
  if (query.svc) {
    params.serviceTypes = ensureArrayOrUndefined(query.svc);
  }
  if (query.crop) {
    params.cropTypes = ensureArrayOrUndefined(query.crop);
  }

  if (query.sort) {
    const key = firstValue(query.sort);
    if (SortOrder[key as keyof typeof SortOrder] !== undefined) {
      params.sort = key as keyof typeof SortOrder;
    }
  }
  if (query.page) {
    const page = firstNumericValue(query.page);
    if (page && page > 1) params.page = page;
  }

  return params;
}

function searchParamsToQuery(
  params: ProductSearchParams,
  defaultSort: NonNullable<ProductSearchParams['sort']>
): ParsedUrlQuery {
  const query: ParsedUrlQuery = {};
  if (params.query) {
    query.q = params.query;
  }
  if (params.postcode) {
    query.pc = params.postcode;
  }
  if (params.location) {
    query.lat = String(params.location.latitude);
    query.lng = String(params.location.longitude);
  }
  if (params.proximity) {
    query.px = String(params.proximity);
  }
  if (params.suppliers) {
    query.s = params.suppliers;
  }
  if (params.serviceTypes) {
    query.svc = params.serviceTypes;
  }
  if (params.cropTypes) {
    query.crop = params.cropTypes;
  }

  if (params.sort && params.sort !== defaultSort) {
    query.sort = params.sort;
  }
  if (params.page && params.page > 1) {
    query.page = String(params.page);
  }

  return query;
}

export function queryToProductSearchParams(query: ParsedUrlQuery): ProductSearchParams {
  return queryToSearchParams(query, PRODUCT_DEFAULT_SORT);
}

export function productSearchParamsToQuery(params: ProductSearchParams): ParsedUrlQuery {
  return searchParamsToQuery(params, PRODUCT_DEFAULT_SORT);
}

export function queryToSupplierSearchParams(query: ParsedUrlQuery): ProductSearchParams {
  return queryToSearchParams(query, SUPPLIER_DEFAULT_SORT);
}

export function supplierSearchParamsToQuery(params: ProductSearchParams): ParsedUrlQuery {
  return searchParamsToQuery(params, SUPPLIER_DEFAULT_SORT);
}

export function proximityLabel(proximity: number): string {
  return proximity === -1 ? 'Unlimited' : `≤ ${proximity} mile` + (proximity === 1 ? '' : 's');
}

export function metersToMiles(meter: number) {
  return meter / METERS_PER_MILE;
}

export function milesToMeters(miles: number) {
  return miles * METERS_PER_MILE;
}

export function distanceInMiles(location1: Point, location2: Point) {
  return metersToMiles(haversineDistance(location1, location2));
}

export function getProfileStatus(supplier?: Supplier | null): ProfileStatus {
  if (!supplier) return 'New';
  if (!supplier.published) return 'Draft';
  if (!supplier.approved) return 'In review';
  return 'Published';
}

export function strapiProductToGraphQLProduct(product: StrapiProduct): Product {
  const image = product.image?.[0];

  return {
    id: product.id,
    name: product.name,
    description: product.description ?? null,
    image: image ? cmsMediaToGraphqlMedia(image) : null,
    promoted_until: product.promoted_until ?? null,
    published: product.publishedAt !== null,
    cropTypes: product.crop_types?.map(crop => ({...crop, id: String(crop.id)})) ?? [],
    serviceType: product.service_type ? {...product.service_type, id: String(product.service_type.id)} : null,
    external_Link: product.external_link ?? null,
    supplierId: product.supplier?.id.toString() ?? null,
  };
}

export function productToProductInput(product: Product): ProductInput {
  return {
    name: product.name,
    description: product.description,
    image: product.image?.id,
    service_type: product.serviceType?.id,
    crop_types: product.cropTypes.map(crop => crop.id),
    promoted_until: product.promoted_until,
    external_link: product.external_Link,
  };
}

export function cmsMediaToGraphqlMedia(media: CMSMedia): Media {
  return {
    id: media.id,
    url: media.url,
    alt: media.alt,
  };
}
export function strapiSupplierToGraphQLSupplier(supplier: StrapiSupplier): Supplier {
  return {
    about: supplier.about,
    address: supplier.address,
    google_place_id: supplier.google_place_id ?? null,
    id: supplier.id,
    latitude: supplier.latitude,
    longitude: supplier.longitude,
    name: supplier.name,
    owner_id: supplier.owner_id,
    phone: supplier.phone ?? null,
    slug: supplier.slug,
    town: supplier.town,
    website: supplier.website ?? null,
    published: supplier.publishedAt !== null,
    approved: supplier.approved,
    image: supplier.image?.[0] ? cmsMediaToGraphqlMedia(supplier.image[0]) : null,
    image_fit: supplier.image_fit ?? null,
  };
}

export function supplierToSupplierInput(supplier: Supplier): SupplierInput {
  return {
    about: supplier.about,
    address: supplier.address,
    google_place_id: supplier.google_place_id,
    latitude: supplier.latitude,
    longitude: supplier.longitude,
    name: supplier.name,
    owner_id: supplier.owner_id,
    phone: supplier.phone,
    slug: supplier.slug,
    town: supplier.town,
    website: supplier.website,
    image: supplier.image?.id,
    image_fit: supplier.image_fit,
  };
}

export function searchParamsSortToStrapiSort(sort: string | undefined, defaultOrder: keyof typeof SortOrder): string[] {
  const sortOrder = (Object.keys(SortOrder).find(key => key === sort) ?? defaultOrder) as keyof typeof SortOrder;

  switch (sortOrder) {
    case 'name':
      return ['name:asc'];
    case '-name':
      return ['name:desc'];
  }

  return [];
}

export function addDays(date: Date, days: number): Date {
  const clone = new Date(date);
  clone.setDate(clone.getDate() + days);
  return clone;
}
export function stripTime(date: Date): Date {
  const clone = new Date(date);
  clone.setHours(0, 0, 0, 0);
  return clone;
}
