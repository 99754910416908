import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

import {emptyInputs, useInputs} from '../../query/useScenario';
import {cropArrayToCropMap, extractCropMap} from '../decision/calculations';
import {CostToGrowInput, runReport, validators} from '../decision/costToGrow';
import {CostReport} from '../decision/types';
import {fillWithDefaults} from '../decision/util';
import {validate, ValidationError} from '../decision/validation';
import {getCrop} from '../resources/crops';
import {useCostMap} from './useCostMap';
import {useLandReport} from './useLandReport';

type UseCostReportResult = {
  report: CostReport | null;
  error: ValidationError | null;
};

const costReportMainKey = 'CostReport';

const getCostReportQueryKey = (scenarioId: string, inputs: any, landReport: any) => [
  scenarioId,
  costReportMainKey,
  JSON.stringify(inputs),
  JSON.stringify(landReport),
];

export const useCostReport = (
  scenarioId: string,
  options?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>
): UseQueryResult<UseCostReportResult, Error> => {
  const {data: scenarioInputs, isSuccess: isSuccessInputs} = useInputs(scenarioId);
  const inputs = scenarioInputs ?? emptyInputs;
  const costMap = useCostMap(scenarioId);
  const {data: landReportData, isSuccess: isSuccessLandReport} = useLandReport(scenarioId);
  const landReport = landReportData?.report;

  return useQuery<UseCostReportResult, Error>({
    ...options,
    staleTime: Infinity,
    queryKey: getCostReportQueryKey(scenarioId, inputs, landReport),
    enabled: isSuccessInputs && isSuccessLandReport,
    queryFn: () => {
      if (!landReport) return {report: null, error: null};

      try {
        const fieldSizes = extractCropMap(landReport.results, 'fieldSizes');
        const annualHarvest = extractCropMap(landReport.results, 'annualHarvest');
        const localYield = extractCropMap(landReport.results, 'matureYield');
        const plantationLifetime = cropArrayToCropMap(
          inputs.plantationLifetime,
          cropId => getCrop(cropId).plantationLifetime
        );
        const salePrice = cropArrayToCropMap(inputs.salePrice, cropId => getCrop(cropId).salePrice);
        const validatedInputs = validate<CostToGrowInput>(
          {
            ...fillWithDefaults(inputs),
            fieldSizes,
            localYield,
            plantationLifetime,
            annualHarvest,
            salePrice,
            costMap,
          },
          inputs,
          validators
        );
        console.log('Generating cost report');
        const report = runReport(validatedInputs);
        return {report, error: null};
      } catch (e: any) {
        if (e instanceof ValidationError) {
          return {report: null, error: e};
        } else {
          throw e;
        }
      }
    },
  });
};
