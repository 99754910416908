const plugin = require('tailwindcss/plugin');
const colors = require('./styles/colors');

module.exports = {
  content: ['./src/**/*.{ts,tsx}'],
  theme: {
    colors,
    fontSize: {
      '2xs': '1.2rem',
      xs: '1.4rem',
      sm: '1.6rem',
      base: '2rem',
      lg: '2.4rem',
      xl: '2.6rem',
      '2xl': '3.2rem',
      '3xl': '4.2rem',
      huge: '5.4rem',
      title: '12rem',
    },
    screens: {
      // Note these are MAX WIDTHS, so they must appear in descending order
      tablet: {max: '1080px'},
      mobile: {max: '750px'},
    },
    extend: {
      borderWidth: {
        thin: '1.5px',
        normal: '0.3rem',
      },
      boxShadow: {
        'solid-green': `-0.4rem 0.4rem 0 0 ${colors.primary['500']}`,
        'solid-light-green': `-0.4rem 0.4rem 0 0 ${colors.primary['300']}`,
        'solid-light-gray': `-0.4rem 0.4rem 0 0 ${colors.gray['100']}`,
      },
      fontFamily: {
        sans: ['HK Grotesk', 'ui-sans-serif', 'system-ui'],
      },
      backgroundImage: {
        logo: 'url(/img/logo.svg)',
        main: 'url(/img/bg-main.svg)',
        dry: 'url(/img/dry.svg)',
        rainy: 'url(/img/rainy.svg)',
        sunny: 'url(/img/sunny.svg)',
        windy: 'url(/img/windy.svg)',
        places: 'url(/img/places.svg)',
        landUses: 'url(/img/landUses.svg)',
        jeopardies1: 'url(/img/jeopardies1.svg)',
        jeopardies2: 'url(/img/jeopardies2.svg)',
        chipBale: 'url(/img/chip-bale.svg)',
      },
      maxWidth: {
        limit: '950px',
        wider: '1280px',
      },
      spacing: {
        xs: '1.6rem',
        sm: '2rem',
        md: '3rem',
        lg: '5rem',
        xl: '7rem',
        '2xl': '8rem',
      },
      transitionProperty: {
        horizontal: 'left, width, transform',
      },
      keyframes: {
        slide: {
          from: {transform: 'translateX(-150%)'},
          to: {transform: 'translateX(150%)'},
        },
        rabbit: {
          '0%': {transform: 'translateX(-100%)'},
          '50%': {transform: 'translateX(60vw)'},
          '75%': {transform: 'translateX(60vw)'},
          '100%': {transform: 'translateX(100vw)'},
        },
        deer: {
          '0%': {transform: 'translateX(-100%)'},
          '100%': {transform: 'translateX(100vw)'},
        },
        fadeIn: {
          from: {opacity: 0},
          to: {opacity: 1},
        },
      },
      animation: {
        'move-truck': 'slide 8s linear forwards',
        rabbit: 'rabbit 9s linear forwards',
        deer: 'deer 9s linear forwards',
        rust: 'fadeIn 2s forwards',
      },
    },
  },
  plugins: [
    require('tailwindcss-labeled-groups')(['navbar']),
    require('tailwind-scrollbar')({nocompatible: true}),
    plugin(function ({addVariant}) {
      // This is not the same as `enabled`. This variant also applies to elements that don't have `disabled` attribute, such as <a>
      addVariant('undisabled', '&:not([disabled])');
      // Match elements that contains the keyboard-focused element
      addVariant('focus-visible-within', '&:has(:focus-visible)');
      // Match elements that contains the keyboard-focused element, when this element has no outline
      addVariant('focus-invisible-within', '&:has(.outline-none:focus-visible)');
    }),
  ],
};
