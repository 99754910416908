import {XMarkIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement, useState} from 'react';

import {QuestionMarkCircleIcon} from '../../../../resources/Icons';
import Col from '../../../common/Col';
import Modal from '../Modal';
import SquareButton from './SquareButton';

type Props = {
  className?: string;
  title?: string;
};
const HelpTooltipButton = ({children, className, title}: PropsWithChildren<Props>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type={'button'}
        className={classNames('w-[2.2rem] h-2.2rem', className)}
        onClick={() => setIsOpen(true)}
        aria-label={'Open help dialogue'}
      >
        <QuestionMarkCircleIcon
          className={classNames(
            'text-white',
            '[&_.outer-circle]:fill-primary-700 [&_.outer-circle]:stroke-primary-700',
            '[&_.outer-circle]:hover:fill-primary-500 [&_.outer-circle]:hover:stroke-primary-500'
          )}
        />
      </button>
      <Modal isOpen={isOpen} className={'grow-0'}>
        <SquareButton
          className={'mt-xs mr-xs self-end border-primary-800'}
          variant={'secondary'}
          onClick={() => setIsOpen(false)}
        >
          <XMarkIcon className={'w-sm'} />
        </SquareButton>
        <Col className={'mx-xl mobile:mx-sm mb-lg mobile:mb-xs gap-xs'}>
          {title && <h1 className={'font-semibold text-3xl leading-tight'}>{title}</h1>}
          {children}
        </Col>
      </Modal>
    </>
  );
};

export default HelpTooltipButton;
