import {Crop, CropId, getCrop} from '../resources/crops';
import {absoluteCollectionCost} from './calculations';
import {
  AnnualFinance,
  AnnualHarvest,
  CostCollectionSet,
  CostReport,
  CropMap,
  HarvestYearDetails,
  Inputs,
} from './types';
import {buildCropMap, sum} from './util';
import {
  mergeValidators,
  noopValidator,
  validateCropPositiveNumbers,
  validateFieldSizes,
  validatePlantationLifetimePerCrop,
  validatePositiveNumber,
  validateRequired,
} from './validation';

export type CostToGrowInput = Pick<
  Inputs,
  'grants' | 'subsidies' | 'isBorrowing' | 'annualBorrowing' | 'upfrontBorrowing' | 'annualBorrowRepayment'
> & {
  localYield: {[cropId in CropId]: number};
  fieldSizes: CropMap<number[]>;
  annualHarvest: {[cropId in CropId]: AnnualHarvest[]};
  plantationLifetime: {[cropId in CropId]: number};
  salePrice: {[cropId in CropId]: number};
  costMap: {[cropId in CropId]: CostCollectionSet};
};

export const validators = {
  localYield: validateRequired,
  fieldSizes: validateFieldSizes,
  plantationLifetime: mergeValidators(validateRequired, validatePlantationLifetimePerCrop),
  salePrice: validateCropPositiveNumbers,
  grants: validatePositiveNumber,
  subsidies: validatePositiveNumber,
  isBorrowing: noopValidator,
  annualBorrowing: validatePositiveNumber,
  upfrontBorrowing: validatePositiveNumber,
  annualBorrowRepayment: validatePositiveNumber,
  annualHarvest: validateRequired,
  costMap: noopValidator, // TODO: Consider if this needs validation
};

/** Calculate the total cost incurred by a specific field for a given year */
function fieldCost({
  actualHarvest,
  costSet,
  fieldIndex,
  fieldSize,
  hasHarvested,
  yearIndex,
}: {
  actualHarvest: number;
  costSet: CostCollectionSet;
  fieldIndex: number;
  fieldSize: number;
  hasHarvested: boolean;
  yearIndex: number;
}) {
  const details: HarvestYearDetails = {yearIndex, fieldIndex, hasHarvested, isHarvest: actualHarvest > 0};
  let costs = 0;
  for (const category of Object.values(costSet)) {
    if (category?.active(details)) {
      costs += absoluteCollectionCost(category, fieldSize, actualHarvest, details);
    }
  }
  return costs;
}

function getAnnualFinance(
  cropId: CropId,
  input: Omit<CostToGrowInput, 'localYield' | 'salePrice'>,
  previousYear: AnnualFinance,
  inflation: number,
  yearHarvest: AnnualHarvest,
  salePrice: number,
  costSet: CostCollectionSet,
  hasHarvested: boolean,
  yearIndex: number,
  totalProducts: number,
  secondaryCrop: NonNullable<Crop['combinedData']>['secondaryCrop'] | undefined
): AnnualFinance {
  const fieldSizes = input.fieldSizes[cropId]; // ha - InitialQuestions.C24
  const grants = input.grants ?? 0; // £ - InitialQuestions.C38
  const subsidies = input.subsidies ?? 0; // £/yr - InitialQuestions.C39
  const annualBorrowing = input.annualBorrowing ?? 0; // £ - InitialQuestions.C45
  const upfrontBorrowing = input.upfrontBorrowing ?? 0; // £ - InitialQuestions.C46
  const annualBorrowRepayment = input.annualBorrowRepayment ?? 0; // £ - InitialQuestions.C48

  const openingBalance = previousYear ? previousYear.closingBalance : 0;
  const cumulativeInflation = previousYear
    ? previousYear.cumulativeInflation * (1 + inflation / 100)
    : 1 + inflation / 100;
  const grantIncome = previousYear ? subsidies * cumulativeInflation : grants + subsidies;
  const borrowingIncome = previousYear ? annualBorrowing * cumulativeInflation : annualBorrowing + upfrontBorrowing;

  const fieldCosts = fieldSizes.map((fieldSize, fieldIndex) =>
    fieldCost({
      costSet,
      yearIndex,
      fieldIndex,
      fieldSize,
      hasHarvested,
      actualHarvest: yearHarvest.portions
        ? yearHarvest.portions.primary.actualHarvest[fieldIndex]
        : yearHarvest.actualHarvest[fieldIndex],
    })
  );

  if (cropId === CropId.willowChip) {
    console.log('Year', yearHarvest.actualHarvest, yearIndex, fieldCosts);
  }

  let costs = sum(fieldCosts);
  costs /= totalProducts; // Divide costs by total products
  costs *= cumulativeInflation; // Apply inflation to costs

  const borrowingPayment = annualBorrowRepayment;
  const inUseHarvest = sum(yearHarvest.inUseHarvest);

  let portions: AnnualFinance['portions'] | undefined = undefined;
  if (yearHarvest.portions) {
    portions = {
      primary: {
        income: sum(yearHarvest.portions.primary.inUseHarvest) * salePrice * cumulativeInflation,
      },
      secondary: {
        income: sum(yearHarvest.portions.secondary.inUseHarvest) * secondaryCrop!.salePrice * cumulativeInflation,
      },
    };
  }

  const income = portions
    ? portions.primary.income + portions.secondary.income
    : inUseHarvest * salePrice * cumulativeInflation;

  const profit = income - costs;
  const closingBalance = openingBalance + profit;

  return {
    openingBalance,
    grantIncome,
    borrowingIncome,
    costs,
    borrowingPayment,
    income,
    profit,
    closingBalance,
    inflation,
    cumulativeInflation,
    portions,
  };
}

function getLifetimeFinance(cropId: CropId, input: CostToGrowInput): CostReport['finance'][CropId] {
  const crop = getCrop(cropId);
  const secondaryCrop = crop.combinedData?.secondaryCrop;
  const salePrice = input.salePrice[cropId]; // £/tonne - Data.R
  const plantationLifetime = input.plantationLifetime[cropId];
  const annualFinance: AnnualFinance[] = [];
  const annualHarvest = input.annualHarvest[cropId];
  const costSet = input.costMap[cropId];
  let hasHarvested = false;

  for (let i = 0; i < plantationLifetime; i++) {
    const inflation = i === 0 ? 0 : 2.5;

    annualFinance[i] = getAnnualFinance(
      cropId,
      input,
      annualFinance[i - 1],
      inflation,
      annualHarvest[i],
      salePrice,
      costSet,
      hasHarvested,
      i,
      crop.totalProducts ?? 1,
      secondaryCrop
    );
    hasHarvested = hasHarvested || annualHarvest[i].actualHarvest.some(harvest => harvest > 0);
  }

  const lifetimeCost = sum(annualFinance.map(year => year.costs));
  const lifetimeIncome = sum(annualFinance.map(year => year.income)); // Turnover
  const lifetimeProfit = sum(annualFinance.map(year => year.profit));

  const totalGreenHarvest = sum(annualHarvest.map(data => sum(data.actualHarvest)));
  const totalDryMatterHarvest = sum(annualHarvest.map(data => sum(data.dryMatterHarvest)));
  const totalInUseHarvest = sum(annualHarvest.map(data => sum(data.inUseHarvest)));

  let secondaryDriedHarvest = undefined;
  let secondaryIncome = undefined;
  let primaryIncome = undefined;
  if (secondaryCrop) {
    primaryIncome = sum(annualFinance.map(year => year.portions?.primary.income ?? 0));
    secondaryIncome = sum(annualFinance.map(year => year.portions?.secondary.income ?? 0));
    secondaryDriedHarvest = sum(annualHarvest.map(data => sum(data.portions?.secondary.inUseHarvest ?? [])));
  }

  let pencePerKwh;
  if (!secondaryCrop) {
    pencePerKwh = (lifetimeCost * 100) / (totalInUseHarvest * crop.cv); // TODO: Receive CV as parameter to allow overriding
  } else {
    pencePerKwh =
      ((secondaryIncome! / lifetimeIncome) * lifetimeCost * 100) / (secondaryDriedHarvest! * secondaryCrop.cv);
  }

  const poundsPerMj = pencePerKwh / 3.6 / 100;
  const poundsPerGj = poundsPerMj * 1000;
  const poundsPerTonne = lifetimeCost / totalInUseHarvest;
  const profitPoundsPerTonne = (lifetimeIncome - lifetimeCost) / totalInUseHarvest;
  const poundsPerHaPerYr = (lifetimeIncome - lifetimeCost) / annualFinance.length / sum(input.fieldSizes[cropId]);

  let poundsPerM3;
  if (!secondaryCrop) {
    poundsPerM3 = (lifetimeIncome - lifetimeCost) / (totalInUseHarvest / crop.bulkDensity);
  } else {
    const primaryPercent = primaryIncome! / lifetimeIncome;
    const secondaryPercent = secondaryIncome! / lifetimeIncome;
    poundsPerM3 =
      (lifetimeIncome - lifetimeCost) /
      (totalInUseHarvest / (primaryPercent * crop.bulkDensity) + secondaryPercent * secondaryCrop.bulkDensity);
  }

  return {
    harvestedMC: crop.harvestedMC,
    inUseMC: crop.inUseMC,
    salePrice,
    costs: costSet,
    annualFinance,
    annualHarvest,
    plantationLifetime,
    lifetime: {
      cost: {
        total: lifetimeCost,
        pencePerKwh,
        poundsPerMj,
        poundsPerGj,
        poundsPerTonne,
      },
      turnover: lifetimeIncome,
      greenHarvest: totalGreenHarvest,
      dryMatterHarvest: totalDryMatterHarvest,
      driedHarvest: totalInUseHarvest,
      profit: {
        total: lifetimeProfit,
        poundsPerTonne: profitPoundsPerTonne,
        poundsPerM3,
        poundsPerHaPerYr,
      },
    },
  };
}

/**
 * @param input Make sure you validate this arg using `validate(input, validators)`
 */
export const runReport = (input: CostToGrowInput): CostReport => {
  return {
    finance: buildCropMap(cropId => getLifetimeFinance(cropId, input)),
  };
};
