import {CheckIcon} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, {ComponentProps, ForwardedRef, forwardRef, ReactElement} from 'react';

type Props = Omit<ComponentProps<'input'>, 'type' | 'ref'> & {inert?: boolean};

const Checkbox = ({inert, className, ...props}: Props, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
  return (
    <>
      <label
        className={classNames(
          'flex-none items-center justify-center w-[20px] h-[20px]',
          'flex flex-col',
          'border-gray-100 focus-visible-within:outline-focus-visible',
          props.disabled && 'bg-gray-100',
          inert && (props.checked ? 'border-none bg-primary-500' : 'border-thin  bg-white'),
          !inert && ['border-thin [&:has(:checked)]:border-none', 'bg-white [&:has(:enabled:checked)]:bg-primary-500'],
          className
        )}
      >
        <CheckIcon className={classNames('text-white h-[14px] w-[14px]')} />
        {!inert && (
          <span className={'absolute w-px h-0 overflow-hidden'}>
            <input type={'checkbox'} {...props} ref={ref} />
          </span>
        )}
      </label>
    </>
  );
};

export default forwardRef<HTMLInputElement, Props>(Checkbox);
