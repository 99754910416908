import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement, UIEvent, useCallback, useState} from 'react';

import Col from '../../common/Col';

const Scrollable = ({children}: PropsWithChildren<{}>): ReactElement => {
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const showHideShadows = useCallback((e: Element) => {
    const atTop = e.scrollTop === 0;
    const atBottom = e.scrollHeight - e.scrollTop - e.clientHeight === 0;
    setShowTopShadow(!atTop);
    setShowBottomShadow(!atBottom);
  }, []);

  const handleScroll = useCallback(
    (evt: UIEvent) => {
      showHideShadows(evt.currentTarget);
    },
    [showHideShadows]
  );

  return (
    <Col
      ref={e => e && showHideShadows(e)}
      onScroll={handleScroll}
      className={classNames(
        'overflow-hidden overflow-y-auto scrollbar-thin',
        'scrollbar-thumb-primary-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-xl scrollbar-track-rounded-xl'
      )}
    >
      {children}
      <Shadow variant={'top'} visible={showTopShadow} />
      <Shadow variant={'bottom'} visible={showBottomShadow} />
    </Col>
  );
};

const Shadow = ({variant, visible}: {variant: 'top' | 'bottom'; visible: boolean}) => (
  <div
    className={classNames(
      'absolute w-full h-4 transition-opacity',
      variant === 'top' ? 'top-0 topScrollShadow' : 'bottom-0 bottomScrollShadow',
      !visible && 'opacity-0'
    )}
  />
);

export default Scrollable;
