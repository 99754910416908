import classNames from 'classnames';
import React, {ReactElement} from 'react';

import Row from '../../common/Row';

type Props = {className?: string; checked: boolean};

const Checkbox = ({className, checked}: Props): ReactElement => {
  return (
    <>
      <Row
        className={classNames(
          'flex-none w-[20px] h-[20px] border-thin border-gray-100 rounded-full',
          'items-center justify-center',
          className
        )}
      >
        <div className={classNames('h-[10px] w-[10px] bg-primary-300 rounded-full', !checked && 'hidden')} />
      </Row>
    </>
  );
};

export default Checkbox;
