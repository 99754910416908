import classNames from 'classnames';
import React, {KeyboardEvent, KeyboardEventHandler, MouseEvent, RefObject, useCallback} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

import {Option, SimpleValue} from '../decision/types';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

export const SelectOption = <T extends SimpleValue>({
  option,
  onOptionSelected,
  checked,
  disabled,
  itemProps,
  accessory,
}: {
  option: Option<T>;
  onOptionSelected: (value: T) => void;
  checked: boolean;
  disabled: boolean;
  itemProps?: ReturnType<typeof useDropdownMenu>['itemProps'][0];
  accessory?: 'radio' | 'checkbox';
}) => {
  // react-accessible-dropdown-menu-hook is too restrictive with the type of item elements (it expects <a> elements).
  // Using <button> actually works, but we need to cast the ref and onKeyDown to the correct type.
  const fixedItemProps = {
    ...itemProps,
    ref: itemProps?.ref as any as RefObject<HTMLButtonElement> | undefined,
    onKeyDown: itemProps?.onKeyDown as any as KeyboardEventHandler<HTMLButtonElement> | undefined,
  };
  const {onKeyDown} = fixedItemProps;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLButtonElement>) => {
      if (onKeyDown) {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
        }
        onKeyDown(e);
      }
    },
    [onKeyDown]
  );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onOptionSelected(option.value);
    },
    [onOptionSelected, option.value]
  );

  return (
    <button
      type={'button'}
      className={classNames(
        'grow flex text-left focus-visible:outline-none',
        'p-4 items-center gap-xs border-thin border-transparent focus:border-primary-300',
        checked && !accessory && 'bg-primary-100',
        disabled ? 'text-gray-200' : 'hover:bg-primary-100 focus:bg-primary-100',
        option.className
      )}
      onClick={handleClick}
      disabled={disabled}
      {...fixedItemProps}
      onKeyDown={handleKeyDown}
    >
      {accessory === 'radio' && <RadioButton checked={checked} />}
      {accessory === 'checkbox' && (
        <Checkbox
          inert={true}
          disabled={disabled}
          checked={checked}
          tabIndex={-1}
          className={'min-h-0 cursor-pointer'}
        />
      )}
      {option.label}
    </button>
  );
};
