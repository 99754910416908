import classNames from 'classnames';
import React from 'react';

import {Option, SimpleValue} from '../../decision/types';
import Select from '../Select';
import AdditionalInfo from './AdditionalInfo';

type PropsType<T extends SimpleValue> = {
  options: Option<T>[];
  value: T | undefined;
  onChange: (value: T) => void | Promise<void>;
  error?: string;
};

const QuestionDropdown = <T extends SimpleValue>(props: PropsType<T>) => {
  const {options, value, onChange, error} = props;
  const currentLabel = value ? options.find(item => item.value == value)?.label : undefined;

  return (
    <>
      <Select
        buttonContent={
          <span className={classNames('truncate', !currentLabel && 'text-gray-200')}>
            {currentLabel ?? 'Select an answer'}
          </span>
        }
        menuClassName={'max-h-[250px]'}
        options={options}
        selectedValues={value === undefined ? [] : [value]}
        onOptionSelected={value => onChange(value)}
      />
      <AdditionalInfo error={error} />
    </>
  );
};

export default QuestionDropdown;
