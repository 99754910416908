import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import React, {ComponentProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

import {ChevronDownIcon} from '../../../resources/Icons';
import Col from '../../common/Col';
import Row from '../../common/Row';
import {Option, SimpleValue} from '../decision/types';
import ModalWithActions from './ModalWithActions';
import Scrollable from './Scrollable';
import {SelectOption} from './SelectOption';

type PropsType<T extends SimpleValue> = {
  id?: string;
  buttonContent: ReactNode;
  variant?: 'primary' | 'secondary' | 'dark';
  options: Option<T>[];
  // renderOption?: (key: number, option: Option<T>, onOptionSelected: <T>(value: T) => void, isSelected: boolean, itemProps: ReturnType<typeof useDropdownMenu>['itemProps'][0]) => ReactNode;
  selectedValues: T[];
  onOptionSelected: (value: T) => void;
  floating?: boolean;
  className?: string; // Applies to whole component
  menuClassName?: string; // Applies to menu
  hideChevron?: boolean;
  disabled?: boolean;
  // Defaults to 'down'
  position?: 'up' | 'down';
  // If true, a modal will be displayed instead of a dropdown menu
  asModal?: boolean;
  accessory?: ComponentProps<typeof SelectOption>[`accessory`];
  disableSelected?: boolean;
  autoclose?: boolean;
  onClearAll?: () => void;
  clearAllLabel?: string;
  heading?: string;
  disableOptionIf?: (option: Option<T>, checked: boolean) => boolean;
};

const Select = <T extends SimpleValue>(props: PropsType<T>) => {
  const {
    id,
    buttonContent,
    variant = 'primary',
    options,
    selectedValues,
    onOptionSelected,
    floating,
    className,
    menuClassName,
    hideChevron,
    disabled,
    position = 'down',
    asModal,
    accessory,
    disableSelected = true,
    disableOptionIf,
    autoclose = true,
    onClearAll,
    clearAllLabel,
    heading,
  } = props;

  const {buttonProps, itemProps, isOpen, setIsOpen} = useDropdownMenu(onClearAll ? options.length + 1 : options.length);
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectOptionSelected = useCallback(
    (value: T) => {
      onOptionSelected(value);
      setIsOpen(!autoclose);
    },
    [autoclose, onOptionSelected, setIsOpen]
  );

  const handleModalOptionSelected = useCallback(
    (value: T) => {
      onOptionSelected(value);
      setModalOpen(!autoclose);
    },
    [autoclose, onOptionSelected]
  );

  const handleClearAll = useCallback(() => {
    onClearAll?.();
    setIsOpen(false);
    setModalOpen(false);
  }, [onClearAll, setIsOpen]);

  const adjustDropdownPosition = useCallback(() => {
    const leftPadding = 36;
    const rightPadding = 22;

    if (dropdownRef.current) {
      // Reset dropdown position
      dropdownRef.current.style.left = '';
      dropdownRef.current.style.right = '';
      dropdownRef.current.style.width = '';
      dropdownRef.current.style.transform = '';
      // Reduce dropdown width if it exceeds window width minus paddings
      let rect = dropdownRef.current.getBoundingClientRect();
      if (rect.width > window.innerWidth - leftPadding - rightPadding) {
        dropdownRef.current.style.width = window.innerWidth - leftPadding - rightPadding + 'px';
      }
      // Move dropdown to the right if it's outside window limit
      rect = dropdownRef.current.getBoundingClientRect();
      if (rect.left <= 0) {
        dropdownRef.current.style.right = rect.left - 28 + 'px';
      }
      // Move dropdown to the top if it's outside window limit
      if (rect.bottom > window.innerHeight) {
        console.log('bottom', rect.bottom, 'window.innerHeight', window.innerHeight);
        dropdownRef.current.style.transform = `translateY(${window.innerHeight - rect.bottom}px)`;
      }
    }
  }, []);

  // Whenever menu is resized or opened, adjust dropdown position to ensure it doesn't fall off page limits
  useResizeObserver(menuRef, () => adjustDropdownPosition());
  useEffect(() => {
    if (isOpen) {
      adjustDropdownPosition();
    }
  }, [adjustDropdownPosition, isOpen]);

  const Content = useMemo(
    () => (
      <Col>
        {(heading || onClearAll) && (
          <Row
            className={classNames(
              'p-4 items-center gap-xs',
              heading && onClearAll && (asModal ? 'flex-col-reverse !items-start' : 'justify-between'),
              !asModal && !heading && onClearAll && 'justify-end'
            )}
          >
            {!!heading && <span className={'text-gray-200'}>{heading}</span>}
            {onClearAll && (
              <button
                key={0}
                type="button"
                onClick={handleClearAll}
                className={classNames(
                  'justify-self-end text-sm text-primary-600 hover:text-shadow',
                  asModal ? 'self-start' : 'self-end'
                )}
                {...(asModal ? [] : (itemProps[0] as any))}
              >
                {clearAllLabel || 'Clear All'}
              </button>
            )}
          </Row>
        )}

        {options.map((opt, idx) => (
          <SelectOption
            key={idx + (onClearAll ? 1 : 0)}
            option={opt}
            onOptionSelected={asModal ? handleModalOptionSelected : handleSelectOptionSelected}
            itemProps={asModal ? undefined : itemProps[idx + (onClearAll ? 1 : 0)]}
            checked={selectedValues.includes(opt.value)}
            disabled={
              (disableSelected && selectedValues.includes(opt.value)) ||
              (disableOptionIf?.(opt, selectedValues.includes(opt.value)) ?? false)
            }
            accessory={accessory}
          />
        ))}
      </Col>
    ),
    [
      heading,
      onClearAll,
      asModal,
      handleClearAll,
      itemProps,
      clearAllLabel,
      options,
      handleModalOptionSelected,
      handleSelectOptionSelected,
      selectedValues,
      disableSelected,
      disableOptionIf,
      accessory,
    ]
  );

  return (
    <div className={classNames('relative space-y-[.9rem] text-sm', className)} ref={menuRef}>
      <button
        id={id}
        onClick={!disabled && asModal ? () => setModalOpen(true) : undefined}
        {...(asModal ? {} : buttonProps)}
        className={classNames(
          'border-thin w-full inline-flex items-center justify-between gap-xs',
          variant === 'primary' && 'border-gray-100 enabled:hover:border-primary-300',
          variant === 'secondary' && !isOpen && 'border-transparent enabled:hover:border-gray-100',
          variant !== 'dark' && isOpen && 'border-primary-300',
          variant === 'dark' && 'text-white bg-primary-700 undisabled:hover:bg-primary-600 focus:bg-primary-600',
          'p-4 rounded-[10px]'
        )}
        type="button"
        disabled={disabled}
      >
        {buttonContent}
        {!hideChevron && (
          <ChevronDownIcon
            className={classNames(
              'w-[1.4rem] shrink-0 transition-transform duration-300',
              !disabled && isOpen && '-rotate-180',
              variant !== 'dark' && !disabled && isOpen && 'text-primary-300'
            )}
          />
        )}
      </button>
      {!asModal && (
        <Col
          role="menu"
          ref={dropdownRef}
          className={classNames(
            'border-thin border-gray-100 rounded-[10px] bg-white overflow-hidden',
            !disabled && isOpen ? 'visible' : 'invisible',
            floating ? 'absolute z-10' : 'relative',
            floating && position === 'down' && 'top-[4.6rem] right-0',
            floating && position === 'up' && 'bottom-[5.6rem] right-0',
            menuClassName
          )}
        >
          <Scrollable>{Content}</Scrollable>
        </Col>
      )}
      {asModal && (
        <ModalWithActions isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
          {Content}
        </ModalWithActions>
      )}
    </div>
  );
};

export default Select;
